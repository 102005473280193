$(document).ready(function() {
    // Ouverture/fermeture du panel
    $(document).on('click', function() {
        $('body').removeClass('body-menu-profile-opened');
        $('body').removeClass('body-menu-navigation-opened');
    });

    $('#bm-menu-navigation-open, #bm-menu-navigation-close').on('click', function(event) {
        event.stopPropagation();
        $('body').removeClass("search-open search-form-in-menu-opened").toggleClass('body-menu-navigation-opened');
    });

    $('#bm-menu-profile-open, #bm-menu-profile-close').on('click', function(event) {
        event.stopPropagation();
        $('body').removeClass("search-open search-form-in-menu-opened").toggleClass('body-menu-profile-opened');
    });

    // Ouverture/fermeture des sous-menus
    $('.has-children').on('click', function(event) {
        event.stopPropagation();
        $(this).toggleClass('opened');
    });

    // Top navigation
    $('.menu-item-top-empty').on('click', function(event) {
        event.stopPropagation();
        $('body').toggleClass('nav-top-opened')
    })


    $('li.nav-icons-item-search button').on('click', function(event) {
        event.stopPropagation();
        $('body').removeClass("body-menu-navigation-opened").toggleClass('search-form-in-menu-opened');
    });

    $('a.close-search, .nav-icons-core-bundle li.nav-icons-item-user a, .nav-icons-core-bundle li.nav-icons-item-user button, .bm-burger-button').on('click', function(event) {
        event.stopPropagation();
        $('body').removeClass('search-form-in-menu-opened');
    })

    // Logout
    $('.menu-item-logout').on('click', function() {
        var logoutUrl = config.backofficeUrl + '/disconnect';
        var jqxhr = $.ajax({
            url: logoutUrl,
            xhrFields: {
                withCredentials: true
            }
        })
        .always(function() {
            document.location.reload();
        });
    })
});
