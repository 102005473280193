import isMobile from "../../../../../node_modules/ismobilejs"

$(document).ready(function() {
  var showResultsClassName = 'opened';
  var backofficeUrl = config.backofficeUrl;
  // var backofficeUrl = 'http://backoffice.local.fr:8081';
  var searchUrl = backofficeUrl + '/search';
  var searchSarpgnUrl = backofficeUrl + '/search-sarpgn';
  var searchSarpspUrl = backofficeUrl + '/search-sarpsp';
  var timer = null;
  var searchResults = [];
  var searchGenerics = [];
  var currentQuery = '';


  function search(searchQuery, callback) {
      // console.log(searchSarpspUrl)
      if(typeof domain != 'undefined' && domain === 'sarpgn') {
          searchUrl = searchSarpgnUrl;
      } else if(typeof domain != 'undefined' && domain === 'sarpsp') {
          searchUrl = searchSarpspUrl;
      }
    $.ajax(searchUrl, {
      type: 'post',
      data: JSON.stringify({query: searchQuery}),
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      success: function(data) {
        callback(data);
      }
    });
  }

  function hideResults() {
    $('body').removeClass("search-open");
    $('.search-form-core-bundle').removeClass(showResultsClassName);
    if ($('agent').length > 0) {
      $('agent')[0].classList.remove('is-hidden');
    }

  }

  function showResultsIfAny() {
    if (searchResults.length > 0 || searchGenerics.length > 0) {
      $('body').removeClass("body-menu-navigation-opened").addClass("search-open");
      $('.search-form-core-bundle').addClass(showResultsClassName);
      if ($('agent').length > 0) {
        $('agent')[0].classList.add('is-hidden');
      }
    }
    openSearchFullscreen();
  }

  function highlightResult(query, result_title){
    var text = result_title,
        between_tags_regex = new RegExp(/^(<.?strong>).?/, 'g');

    // Emphasis the matched spellChecks
    if (query.spellChecked.length > 0){
      query.spellChecked.forEach(function (word) {
        if (word.length >= 3){
          var word_regex = new RegExp(word, 'ig');

          text = text.replace(word_regex, '<strong>' + word + '</strong>');
        }
      });

      // Ensure the very first letter is uppercase (as the spellChecks are lowercase)
      var first_char_match = text.match(between_tags_regex);
      if (text.indexOf(first_char_match) === 0){
        var start = '<strong>' + text.match(between_tags_regex)[0].replace('<strong>', '')
            .charAt(0).toUpperCase();
        text = text.replace(first_char_match, start);
      }
    }

    return text;
  }

  function detectBreakpoint() {
    if (window.innerWidth < 992) {
      return true;
    }

    return false;
  }

  function openSearchFullscreen() {
    // change l'affichage du champ de recherche sur mobile (full screen)
    var smallBreakpoint = detectBreakpoint();

    $(window).on('resize', function(event) {
      smallBreakpoint = detectBreakpoint();
    });

    if (isMobile.tablet || isMobile.any || smallBreakpoint === true) {
      // if (smallBreakpoint == true) {
      setTimeout(function() {
        window.scrollTo(0, 0);
      }, 500);
      $('body').removeClass("body-menu-navigation-opened").addClass("search-form-in-menu-opened");
    }
  }

  function closeSearchFullscreen() {
    $('body').removeClass("search-form-in-menu-opened");
  }

  // Repositionne le champ dans la page pour visibilité des résultats
  function placeFieldInScreen() {
    if (!document.body.classList.contains('body-search-fullscreen')) {
      var scrollToTop = window.setInterval(function() {
        var pos = window.pageYOffset;
        if (pos > 0) {
          window.scrollTo(0, pos - 20);
        } else {
          window.clearInterval(scrollToTop);
        }
      }, 30);
    }
  }

  $('.btn-close-search').on('click', function(event) {
    closeSearchFullscreen();
  });

  // Récupération des liens favoris
  if ($('.search-form-core-bundle form')) {
    search('', function(data) {
      for (var i in data.favoris) {
        var fav = data.favoris[i];
        searchGenerics.push(fav);
        $('ul.search-links').append(
          '<li><a href="' +
            backofficeUrl +
            '/api/open/link/' +
            fav.id +
            '/redirect?searchQuery=" class="btn btn-blue-inverse btn-search">' +
            fav.title +
            '</a></li>'
        );
      }
    });
  }

  $('.search-form-core-bundle form input')
    .on('submit', function(event) {
      event.preventDefault();
    })

    .on('focus', function() {
      showResultsIfAny();
      placeFieldInScreen();
    })

    .on('blur', function() {
      setTimeout(function() {
        hideResults();
      }, 200);
    })

    .on('keyup', function(event) {
      clearTimeout(timer);
      timer = setTimeout(function() {
        search(event.currentTarget.value, function(data) {
          $('ul.search-results').empty();
          if (data.query.raw != '') {
            // No results
            if (data.results.length === 0) {
              $('ul.search-results').append('<li class="no-result">Aucun résultat ne correspond à votre recherche</li>')
            }
            for (var i in data.results) {
              var result = data.results[i],
                  link_content = highlightResult(data.query, result.title);
              searchResults.push(result);
              $('ul.search-results').append(
                '<li><a href="' +
                  backofficeUrl +
                  '/api/open/link/' +
                  result.id +
                  '/redirect?searchQuery=' +
                  data.query.raw +
                  '">' +
                   link_content +
                  '</a></li>'
              );
            }
          }
        });
      }, 400);
    });
});
