'use strict';

function cookieFunction(g) {
  function init() {
    var cookieName = 'MDPA-ACCEPT-COOKIES';

    if (!Cookies.get(cookieName)) {
      $('.cookie-core-bundle').removeClass('hidden');
    }

    $('.btn-cookie-ok').click(function() {
      acceptCookies(cookieName);
    });

    $('.btn-cookie-close').click(function() {
      $('.cookie-core-bundle').addClass('hidden');
    });
  }

  function acceptCookies(theCookie) {
    Cookies.set(theCookie, Date.now(), {expires: 365, domain: '.assurance-mutuelle-poitiers.fr'});
    $('.cookie-core-bundle').addClass('hidden');
  }

  init();
}
