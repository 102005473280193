import '../node_modules/select2/dist/js/select2.js';
import '../node_modules/jquery/dist/jquery.js';
import '../node_modules/jquery-inview/jquery.inview.min.js';
import "../node_modules/bootstrap-sass/assets/javascripts/bootstrap.js";
import './js/app.js';
import '../vendor/mdpa/core-bundle/src/Resources/assets/js/common-core'
import "../vendor/mdpa/core-bundle/src/Resources/assets/js/modules/menu-navigation";
import "../vendor/mdpa/core-bundle/src/Resources/assets/js/modules/search";
import "../vendor/mdpa/core-bundle/src/Resources/assets/js/modules/detect-mobile";
import "../vendor/mdpa/core-bundle/src/Resources/assets/js/modules/cookie";
import "../vendor/mdpa/core-bundle/src/Resources/assets/js/modules/browser";
