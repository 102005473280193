'use strict';

function browserFunction(g) {
    var browser = getNavigator();

    function init() {
        if (browser[0] != '') {
            if ($('#browserName').length > 0) {
                $('#browserName').html(browser[0] + ' ' + browser[1]);
            }
            g.body.addClass('body-browser-' + browser[0].toLowerCase());
        }
    }

    function getNavigator() {
        var version = '';
        var navigateur = '';
        var ua = navigator.userAgent;

        // IE
        if (ua.indexOf('MSIE') != -1) {
            var detectIEregexp = /MSIE (\d+\.\d+);/;
        } else {// if no "MSIE" string in userAgent
            var detectIEregexp = /Trident.*rv[ :]*(\d+\.\d+)/;
        }

        if (detectIEregexp.test(ua)) {
            version = new Number(RegExp.$1);
            navigateur = 'ie';
        }

        // FF
        if (/Firefox[\/\s](\d+\.\d+)/.test(ua)) {
            version = new Number(RegExp.$1);
            navigateur = 'firefox';
        }

        // CHROME
        if (/Chrome[\/\s](\d+\.\d+)/.test(ua) || /CriOS[\/\s](\d+\.\d+)/.test(ua)) {
            version = new Number(RegExp.$1);
            navigateur = 'chrome';
        }

        // SAFARI
        if (/Safari/.test(ua) && /Version[\/\s](\d+\.\d+)/.test(ua)) {
            version = new Number(RegExp.$1);
            navigateur = 'Safari';
        }

        // OPERA
        var opera15andabovever = /OPR\/(\d+\.\d+)/i.test(ua);

        if (opera15andabovever) {
            version = new Number(RegExp.$1);
            navigateur = 'Opera';
        }

        if (/Opera[\/\s](\d+\.\d+)/.test(ua)) {
            version = new Number(RegExp.$1);
            navigateur = 'Opera';
        }

        return [navigateur, version];
    }

    init();
}
