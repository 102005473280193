'use strict';

function common(g) {
    function init() {
        attachScrollListener();
    }

    function attachScrollListener() {
        window.addEventListener('scroll', function() {
            var scrollPosY = window.pageYOffset | document.body.scrollTop;
            document.body.classList.remove('scrolling');

            if (scrollPosY > 0) {
                document.body.classList.add('scrolling');
            }
        });
    };
    init();
}
