$( document ).ready(function() {

    inviewFunction();

    /**
     * animated scroll
     */
    $('.scrollTo').click( function() {
        var page = $(this).attr('href');
        var speed = 750;
        $('html, body').animate( { scrollTop: $(page).offset().top }, speed );
        return false;
    });

    /**
     * toggle agency mobile menu
     */
    $(".nav-agency").find(".toggle-menu").on("click", () => {
        $("body").toggleClass("agency-tabs-opened");
    });

    /**
     * bigger link behavior
     */
    $('.is-link').each(function(index) {
        if (0 == $(this).find('a').length) {
            $(this).removeClass('is-link');
        }
    });

    $('.is-link').click(function(event) {
        event.preventDefault();
        var link = $(this).find('a:first').attr('href');
        console.log(link);
        if (link !== undefined) {
            location.href = link;
        }
    });

    /**
     * cookie bar
     */
    if ( $( ".cookie" ).length ) {

        var globalCookie = $( ".cookie" );

        globalCookie.find( ".btn-ag-cookie-ok, .btn-ag-cookie-close" ).click( function() {

            var expDate = new Date();

            var valueCookie = expDate.getTime();

            expDate.setFullYear( expDate.getFullYear() + 1 );

            try {
                setCookie( cookieName, valueCookie, expDate, "/", getCookieDomain() );
            } catch( e ) { }

            globalCookie.fadeOut();
        } );
    }

    /**
     * function to set cookie
     */

    function setCookie( name, value, expire, path, domain, security ) {
        document.cookie = name + " = " + escape( value ) + "  " +
        ( ( expire == undefined ) ? "" : ( "; expires = " + expire.toGMTString() ) ) +
        ( ( path == undefined ) ? "" : ( "; path = " + path ) ) +
        ( ( domain == undefined ) ? "" : ( "; domain = " + domain ) ) +
        ( ( security == true ) ? "; secure" : ""
        );
    }

    function getCookieDomain() {
        var hostname = window.location.hostname;
        var subdomain = window.location.href.split("/")[2].split(".")[0];

        return hostname.replace(subdomain, "");
    }

    /**
     * Select 2
     */
    $('.custom-agencie-select').select2({
        placeholder: '',
        placeholder: $(this).data("placeholder")
    });

    /**
     * Tooltip
     */
    $(function () {
        $('[data-toggle="tooltip"]').tooltip();
    });

    /**
     * Bootstrap Modal
     * Fix Modal-backdrop z-index problem
     */
    $('.modal-help').on('shown.bs.modal', function() {
        //To relate the z-index make sure backdrop and modal are siblings
        $(this).before($('.modal-backdrop'));
        //Now set z-index of modal greater than backdrop
        $(this).css("z-index", parseInt($('.modal-backdrop').css('z-index')) + 1);
    });

    /**
     * scrollTo sur la page agence après sélection du CP
     */
    if( $( ".agency-home #list-agency" ).length ) {
        $('html, body').animate( { scrollTop: $('.list-agency').offset().top - 130 }, 750 );
    }

    if( $( ".agency-home #no-agency" ).length ) {
        $('html, body').animate( { scrollTop: $('.no-agency').offset().top - 130 }, 750 );
    }

    /**
     * Resize Recherche_box
     */
    function resizeRechercheBox(input) {

        input.each(function(){
            var inputWidth = $(this).outerWidth(),
                rechercheBox = $("#recherche_box");

            if( rechercheBox.length ) {
                rechercheBox.css("min-width",inputWidth);
            }
        });

    }

    $(window).bind("resize", function () {
        resizeRechercheBox($(".input-autocomplete"));
    });

    /**
     * Agence HP : recherche par code postal et code insee
     */
    if( $( "#formSearchAgency" ).length ) {
        var formSearchAgency = $( "#formSearchAgency"),
            inputAgencySearch = formSearchAgency.find("#search_agency_recherche"),
            formGroupAgencySearch = inputAgencySearch.closest(".form-group"),
            inputAgencyCp = formSearchAgency.find("#search_agency_cp"),
            inputAgencyCi = formSearchAgency.find("#search_agency_ci"),
            reqAgency = null;


        inputAgencySearch.keyup(debounce(function(e) {
            e.preventDefault();
            if ($(this).val().length < 3) {
                return;
            }

            inputAgencyCp.val('');
            inputAgencyCi.val('');

            if (reqAgency != null)
                reqAgency.abort();

            /* add loader */
            inputAgencySearch.addClass("loader-img");
            reqAgency = $.ajax({
                type: 'POST',
                url: ajaxLocalitySearch,
                data: $.param({
                  'search': $(this).val()
                }),
                success: function (result, textStatus, request) {
                    var i = 0;
                    var items;

                    items = '<li tabindex="' + i + '" class="active"><i>Sélectionner une commune</i></li>';
                    i++;

                    $.each(result, function (key) {
                        var id = this.codePostal + '_' + this.codeInsee;
                        items += '<li tabindex="' + i + '" id="' + id + '"' + (i == 0 ? ' class="active"' : '') + '>' + this.codePostal + ' ' + this.commune + '</li>';
                        i++;
                    });
                    if (i > 0) {
                        $('#recherche_box').remove();
                        var html = '<div id="recherche_box"><ul>' + items + '</ul><div id="recherche_box_close"><i class="fa fa-close"></i></div></div>';
                        $(html).insertAfter(inputAgencySearch);
                        resizeRechercheBox(inputAgencySearch);
                    }
                    if (i == 1) {
                        $('#recherche_box').remove();

                        $(".autocomplete-no-find").removeClass("is-hidden");
                        formGroupAgencySearch.addClass("has-error");

                    } else {
                        $(".autocomplete-no-find").addClass("is-hidden");
                        formGroupAgencySearch.removeClass("has-error");
                    }

                    /* remove loader */
                    inputAgencySearch.removeClass("loader-img");
                }
            });
        }, 600));

        /**
         * Create a timer for execute function after X milliseconds
         *
         * @param callback
         * @param delay
         * @returns {Function}
         */
        function debounce(callback, delay){
            var timer;
            return function(){
                var args = arguments;
                var context = this;
                clearTimeout(timer);
                timer = setTimeout(function(){
                    callback.apply(context, args);
                }, delay)
            }
        }

        // evenement au click dans le champ de recherche
        inputAgencySearch.on('click', function (e) {
            e.preventDefault();

            if($('#recherche_box').length == 0) {
                inputAgencySearch.trigger('keyup');
            }
        });

        // evenement de fermeture de la box
        $(document).on('click', '.page-content', function (e) {
            if (e.target.id != "#recherche_box li") {
                $('#recherche_box').remove();
                inputAgencySearch.focus();
            }
        });

        // evenement au click sur une Place
        $(document).on('click', '#recherche_box li', function () {
            validAgencySearch($(this));
            inputAgencySearch.focus();
        });

        // evenements de navigation au sein des Places de la box
        $(document).keydown(function (e) {
            if (e.keyCode == 38 && $('#recherche_box li.active').prev('li').length > 0) {
                $('#recherche_box li.active').removeClass('active').prev('li').addClass('active').focus();
                return false;
            }
            if (e.keyCode == 40 && $('#recherche_box li.active').next('li').length > 0) {
                $('#recherche_box li.active').removeClass('active').next('li').addClass('active').focus();
                return false;
            }
            if (e.keyCode == 13 && $('#recherche_box li.active').length > 0 && $('#recherche_box li.active').attr('tabindex') != 0) {
                validAgencySearch($('#recherche_box li.active'));
            }
        });

        function validAgencySearch(activeItem) {
            var codes = activeItem.attr('id').split('_');
            inputAgencyCp.val(codes[0]);
            inputAgencyCi.val(codes[1]);

            inputAgencySearch.val(activeItem.html());
            $('#recherche_box').remove();
            $('button').removeAttr("disabled");
            inputAgencySearch.trigger("blur");
            /* remove loader */
            inputAgencySearch.removeClass("loader-img");
        }
    }

    /**
     * Get job offers from backoffice
     */
    if( $('#job-offers').length ) {
        reqOffreRecrutement = $.ajax({
            type: 'GET',
            url: offresParAgenceApiUrl
        })
            .done(function (result) {
                var listeOffreHtml = `
                    <li class="is-link">
                        <span class="date"></span>
                        <h3></h3>
                        <span class="infos"></span>
                        <p></p>
                        <hr>
                        <a class="btn btn-link btn-green">
                            Voir l'annonce
                            <span class="iicon iicon-chevron-thin-right"></span>
                        </a>
                    </li>
                `;

                $('#list-offers').html(listeOffreHtml);

                result.forEach(function (val, key) {
                    var options = { year: 'numeric', month: 'long', day: 'numeric' },
                        datePublication = new Date(val.datePublication).toLocaleDateString('fr-FR', options);

                    if ( 0 === key) {
                        $('#list-offers > li').attr('id', 'li-offre-' + key);
                    } else {
                        $('#list-offers').append( $('#li-offre-' + (key - 1) ).clone().attr('id', 'li-offre-' + key) );
                    }

                    $('#li-offre-' + key + ' span.date').text('Le ' + datePublication);
                    $('#li-offre-' + key + ' h3').text(val.titre);
                    $('#li-offre-' + key + ' span.infos').text('(' + val.localisation + ') ' + val.ville + ' | ' + val.contract.name + ' | ' + val.structure);
                    $('#li-offre-' + key + ' p').text(val.descriptionCourte);
                    $('#li-offre-' + key + ' a').attr('href', offreRecrutementUrl + val.slug);

                });

                $('.is-link').on("click", function(event) {
                    event.preventDefault();
                    var link = $(this).find('a:first').attr('href');

                    if (link !== undefined) {
                        location.href = link;
                    }
                });
            })
            .fail(function () {
                $('.push-no-offers').removeClass('is-hidden');
                $('.job-loader').addClass('is-hidden');
            })
            .always(function () {
                $('.job-loader').remove();
            })
    }

    function inviewFunction(g) {
        function init() {
            $('.to-animate').one('inview', function(event, isInView) {
                if (isInView) {
                    // element is now visible in the viewport
                    console.log('poke');
                    $(this).addClass('is-animated').find('span.number').each(function() {
                        var $this = $(this);
                        var countTo = $this.data('count');
                        var formatThousands = function(n, dp) {
                            var s = ''+(Math.floor(n));
                            var d = n % 1;
                            var i = s.length;
                            var r = '';

                            while ( (i -= 3) > 0 ) {
                                r = ' ' + s.substr(i, 3) + r;
                            }
                            return s.substr(0, i + 3) + r + (d ? '.' + Math.round(d * Math.pow(10, dp || 2)) : '');
                        };

                        $({countNum: $this.text()}).animate({
                                countNum: countTo
                            },
                            {
                                duration: 1500,
                                easing: 'linear',
                                step: function() {
                                    $this.text(formatThousands(Math.floor(this.countNum)));
                                },
                                complete: function() {
                                    $this.text(formatThousands(this.countNum));
                                }
                            });
                    });
                }
            });
        }

        init();
    }

});
