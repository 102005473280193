'use strict';

function detectMobileFunction() {
  function init() {
    if (isMobile.any) {
      document.body.classList.add('isMobile');
    }
  }
  init();
}
